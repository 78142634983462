import { render, staticRenderFns } from "./ProductServiceEditor.vue?vue&type=template&id=54abe381&scoped=true"
import script from "./ProductServiceEditor.vue?vue&type=script&lang=js"
export * from "./ProductServiceEditor.vue?vue&type=script&lang=js"
import style0 from "./ProductServiceEditor.vue?vue&type=style&index=0&id=54abe381&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_obqfykji6yask4lox7dm6zddym/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54abe381",
  null
  
)

export default component.exports